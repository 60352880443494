<!-- eslint-disable vue/no-v-html -->

<template>
  <div id="formula-builder">
    <!-- label -->

    <FormFieldLabel label="formula" is-mandatory />

    <!-- ... -->

    <!-- wrapper -->

    <div class="wrapper" :class="{ error: error }">
      <!-- tokens -->

      <div class="tokens">
        <div
          v-for="token in tokens"
          :key="token.id"
          class="token"
          @click="addToken(token.name, token.value)"
        >
          <span v-html="token.code"></span>
        </div>
      </div>

      <!-- ... -->

      <BaseSeparator is-vertical class="q-mx-md" />

      <!-- formula -->

      <div class="formula">
        <template v-for="(token, idx) in formula">
          <!-- field -->

          <div
            v-if="token.name === 'FIELD'"
            :key="token.id"
            class="input field"
          >
            <div class="full-width">
              <div class="row items-center">
                <div class="col label ellipsis">
                  <span v-if="token.label">{{ token.label }}</span>
                  <span v-else class="placeholder">Select</span>
                </div>
                <BaseIcon name="eva-chevron-down" inherit-color class="icon" />
              </div>

              <q-menu
                fit
                auto-close
                transition-show="scale"
                transition-hide="scale"
              >
                <ListItem
                  v-for="field in _numericFields"
                  :key="field.id"
                  :is-selected="token.value === field.value"
                  :label="field.label"
                  class="text-capitalize"
                  @click="
                    token.label = field.label;
                    token.value = field.value;
                  "
                />
              </q-menu>
            </div>

            <BaseIcon
              name="eva-close"
              color="red"
              class="delete-icon"
              @click="formula.splice(idx, 1)"
            />
          </div>

          <!-- ... -->

          <!-- number -->

          <div
            v-else-if="token.name === 'NUMBER'"
            :key="token.id"
            class="input number"
          >
            <input
              :value="token.value"
              type="number"
              @input="
                (e) => {
                  token.label = e.target.value;
                  token.value = e.target.value;
                }
              "
            />

            <BaseIcon
              name="eva-close"
              color="red"
              class="delete-icon"
              @click="formula.splice(idx, 1)"
            />
          </div>

          <!-- ... -->

          <!-- other tokens -->

          <div v-else :key="token.id" class="token">
            <span v-html="getHTMLCode(token.name)"></span>

            <BaseIcon
              name="eva-close"
              color="red"
              class="delete-icon"
              @click="formula.splice(idx, 1)"
            />
          </div>

          <!-- ... -->
        </template>
      </div>

      <!-- ... -->
    </div>

    <!-- ... -->

    <!-- error -->

    <FormFieldError v-if="error" :error="error" />

    <!-- ... -->
  </div>
</template>

<script>
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import ListItem from "@/components/common/ListItem.vue";
import { cloneDeep, isEqual } from "lodash-es";

export default {
  name: "FormulaBuilder",

  components: {
    FormFieldLabel,
    FormFieldError,
    ListItem,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },

    error: {
      type: String,
      default: "",
    },

    numericFields: {
      type: Array,
      default: () => [],
    },

    isTable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tokens: [
        {
          id: this.$nano.id(),
          code: "Field",
          name: "FIELD",
          value: "",
        },
        {
          id: this.$nano.id(),
          code: "&#43;",
          name: "PLUS",
          value: "+",
        },
        {
          id: this.$nano.id(),
          code: "&#8722;",
          name: "MINUS",
          value: "-",
        },
        {
          id: this.$nano.id(),
          code: "&#215;",
          name: "MULTIPLY",
          value: "*",
        },
        {
          id: this.$nano.id(),
          code: "&#247;",
          name: "DIVIDE",
          value: "/",
        },
        {
          id: this.$nano.id(),
          code: "&#40;",
          name: "LEFT_PARANTHESES",
          value: "(",
        },
        {
          id: this.$nano.id(),
          code: "&#41;",
          name: "RIGHT_PARANTHESES",
          value: ")",
        },
        {
          id: this.$nano.id(),
          code: "Number",
          name: "NUMBER",
          value: "",
        },
      ],
      formula: [],
    };
  },

  computed: {
    _numericFields() {
      if (this.isTable) {
        let fields = [];
        this.numericFields.forEach((number) => {
          if (number.type === "NUMBER") {
            fields.push(number);
            fields.push({
              ...number,
              label: `SUM OF [${number.label}]`,
              value: `SUM OF ${number.value}`,
            });
          } else {
            fields.push(number);
          }
        });
        return fields;
      }
      return this.numericFields;
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        if (isEqual(this.value, this.formula)) {
          return;
        }

        this.formula = cloneDeep(this.value);
      },
    },

    formula: {
      deep: true,
      handler() {
        this.$emit("input", this.formula);
      },
    },
  },

  methods: {
    addToken(name, label) {
      this.formula.push({
        id: this.$nano.id(),
        name: name,
        label: label,
        value: "",
      });
    },

    getHTMLCode(tokenName) {
      return this.tokens.find((token) => token.name === tokenName).code;
    },
  },
};
</script>

<style lang="scss" scoped>
#formula-builder {
  .wrapper {
    display: flex;
    align-items: flex-start;
    border: 1px solid var(--divider-color);
    border-radius: 4px;
    padding: 16px;

    &.error {
      border: 1px solid var(--red);
    }

    .token {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      border: 1px solid var(--divider-color);
      border-radius: 36px;
      font-size: 24px;
      font-weight: 500;
      color: var(--icon-color);
      user-select: none;
      position: relative;

      .delete-icon {
        display: none;
        position: absolute;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: var(--component-bg-color);
        border-radius: 36px;
        cursor: pointer;
      }

      &:hover {
        .delete-icon {
          display: flex;
        }
      }
    }

    .tokens {
      min-width: 88px;
      width: 88px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;

      .token {
        background-color: var(--body-bg-color-light);
        border-radius: 4px;

        &:hover {
          cursor: pointer;
          background-color: var(--hover-bg-color);
        }

        &:first-child,
        &:last-child {
          width: 100%;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    .formula {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 8px;

      .input {
        border: 1px solid var(--border-color);
        border-radius: 4px;
        height: 40px;
        padding: 8px;
        display: flex;
        align-items: center;
        transition: width 0.3s ease;

        .label {
          font-weight: 500;
          margin-right: 8px;
        }

        .icon {
          color: var(--icon-color-inverted);
        }

        .delete-icon {
          display: none;
          cursor: pointer;
        }

        &.field {
          // width: 120px;
          cursor: pointer;

          &:hover {
            // width: 140px;

            .delete-icon {
              display: inline-block;
            }
          }
        }

        &.number {
          width: 56px;

          &:hover {
            width: 76px;

            .delete-icon {
              display: inline-block;
            }
          }
        }
      }
    }
  }
}
</style>
